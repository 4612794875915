/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { connect } from 'react-redux'
import auth from 'app/common/auth'
import { get, split, first } from 'lodash'
import { toAbsoluteUrl } from '../../../_metronic'
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle'

class UserProfile extends React.Component {
  render() {
    const {
      showHi, showAvatar, showBadge,
    } = this.props

    const user = get(auth.get(), 'user')
    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Xin chào
              </span>
            )}

            {user && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                 {first(split(user.email, '@'))}
              </span>
            )}

            {/* {showAvatar && <img alt="Pic" src={user.pic} />} */}
            {user && <img alt="Pic" src={'/media/users/300_25.jpg'} />}

            {/* {user && ( */}
            {/*  <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"> */}
            {/*    /!* TODO: Should get from currentUser *!/ */}
            {/*    {first(split(user.email, '@'))} */}
            {/*  </span> */}
            {/* )} */}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
            }}
          >
            <div className="kt-user-card__avatar">
              {/* <img alt="Pic" className="kt-hidden" src={user.pic} /> */}
              <img alt="Pic" className="kt-hidden" src={'/media/users/300_25.jpg'} />
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                {first(split(user.email, ''))}
              </span>
            </div>
            <div className="kt-user-card__name">{first(split(user.email, '@'))}</div>
          </div>
          <div className="kt-notification">
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(UserProfile)
