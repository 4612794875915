import _ from 'lodash'
import jwt from 'jsonwebtoken'

const authenticate = () => {
  let keep = {}
  const privateKey = '9ol.@WSX'

  const get = () => {
    try {
      // Get from closure
      if (!_.isEmpty(keep)) return keep

      // Or get from local storage
      let localStore = localStorage.getItem('keep')
      if (!_.isEmpty(localStore)) {
        localStore = jwt.verify(localStore, privateKey)
        return localStore
      }

      // Nothing
      return {}
    } catch (e) {
      del()
      return {}
    }
  }

  const set = (token) => {
    try {
      keep = _.isEmpty(token) ? {} : token
      const jwtToken = jwt.sign(keep, privateKey)
      localStorage.setItem('keep', jwtToken)
      return true
    } catch (e) {
      del()
    }
  }

  const del = () => {
    keep = {}
    localStorage.removeItem('keep')
    return true
  }

  const isAuth = () => {
    const accessToken = _.get(get(), 'token')
    return !_.isEmpty(accessToken)
  }

  const setRole = (role) => {
    const data = get()

    set({ ...data, role })
  }

  return {
    get,
    set,
    del,
    isAuth,
    setRole,
  }
}

const auth = authenticate()

export default auth
