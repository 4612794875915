import React from 'react'

export default class MenuItemSeparator extends React.Component {
  render() {
    return (
      <li className="kt-menu__separator">
        <span />
      </li>
    )
  }
}
