import {
  upperCase,
  get,
} from 'lodash'
import auth from 'app/common/auth'
import { SERVER_API } from 'app/common/const'

function api(url, _method = 'GET', data = {}) {
  const method = upperCase(_method)
  const token = get(auth.get(), 'token')
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    Authorization: `Bearer ${token}`,
  }
  switch (method) {
    case 'POST':
    case 'PUT':
    case 'DELETE':
      return fetch(`${SERVER_API}/${url}`, {
        method,
        headers,
        body: JSON.stringify(data),
      }).then((response) => {
        if (response.status === 200) return response.json()
        if (response.status === 422) {
          return response.json().then((value) => ({
            status: response.status,
            data: value,
          }))
        }

        return Promise.reject('Error')
      })
    case 'GET':
    default:
      return fetch(`${SERVER_API}/${url}`, {
        method,
        headers,
      })
        .then((response) => {
          if (response.status === 200) return response.json()
          if (response.status === 422) {
            return response.json().then((value) => ({
              status: response.status,
              data: value,
            }))
          }
          return Promise.reject('Error')
        })
  }
}

export default api
