import React, { Fragment } from 'react'
import '../assets/layout.scss'

function Layout(props) {
  return (
    <Fragment>
      {props.children}
    </Fragment>
  )
}

export default Layout
