/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-loop-func,no-undef,no-restricted-globals,strict,no-unused-vars,no-cond-assign,eqeqeq,no-throw-literal,no-unreachable,no-useless-escape,no-redeclare,no-useless-concat,no-unused-expressions,no-sequences */
import KTUtil from './util'

'use strict'
const KTMenu = function (elementId, options) {
  // Main object
  let the = this
  let init = false

  // Get element object
  const element = KTUtil.get(elementId)
  const body = KTUtil.get('body')

  if (!element) {
    return
  }

  // Default options
  let defaultOptions = {
    // scrollable area with Perfect Scroll
    scroll: {
      rememberPosition: false,
    },

    // accordion submenu mode
    accordion: {
      slideSpeed: 200, // accordion toggle slide speed in milliseconds
      autoScroll: false, // enable auto scrolling(focus) to the clicked menu item
      autoScrollSpeed: 1200,
      expandAll: true, // allow having multiple expanded accordions in the menu
    },

    // dropdown submenu mode
    dropdown: {
      timeout: 500, // timeout in milliseconds to show and hide the hoverable submenu dropdown
    },
  }

  // //////////////////////////
  // ** Private Methods  ** //
  // //////////////////////////

  var Plugin = {
    /**
         * Run plugin
         * @returns {KTMenu}
         */
    construct(options) {
      if (KTUtil.data(element).has('menu')) {
        the = KTUtil.data(element).get('menu')
      } else {
        // reset menu
        Plugin.init(options)

        // reset menu
        Plugin.reset()

        // build menu
        Plugin.build()

        KTUtil.data(element).set('menu', the)
      }

      return the
    },

    /**
         * Handles submenu click toggle
         * @returns {KTMenu}
         */
    init(options) {
      the.events = []

      the.eventHandlers = {}

      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, options)

      // pause menu
      the.pauseDropdownHoverTime = 0

      the.uid = KTUtil.getUniqueID()
    },

    update(options) {
      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, options)

      // pause menu
      the.pauseDropdownHoverTime = 0

      // reset menu
      Plugin.reset()

      the.eventHandlers = {}

      // build menu
      Plugin.build()

      KTUtil.data(element).set('menu', the)
    },

    reload() {
      // reset menu
      Plugin.reset()

      // build menu
      Plugin.build()

      // reset submenu props
      Plugin.resetSubmenuProps()
    },

    /**
         * Reset menu
         * @returns {KTMenu}
         */
    build() {
      // General accordion submenu toggle
      the.eventHandlers.event_1 = KTUtil.on(element, '.kt-menu__toggle', 'click', Plugin.handleSubmenuAccordion)

      // Dropdown mode(hoverable)
      if (Plugin.getSubmenuMode() === 'dropdown' || Plugin.isConditionalSubmenuDropdown()) {
        // dropdown submenu - hover toggle
        the.eventHandlers.event_2 = KTUtil.on(element, '[data-ktmenu-submenu-toggle="hover"]', 'mouseover', Plugin.handleSubmenuDrodownHoverEnter)
        the.eventHandlers.event_3 = KTUtil.on(element, '[data-ktmenu-submenu-toggle="hover"]', 'mouseout', Plugin.handleSubmenuDrodownHoverExit)

        // dropdown submenu - click toggle
        the.eventHandlers.event_4 = KTUtil.on(element, '[data-ktmenu-submenu-toggle="click"] > .kt-menu__toggle, [data-ktmenu-submenu-toggle="click"] > .kt-menu__link .kt-menu__toggle', 'click', Plugin.handleSubmenuDropdownClick)
        the.eventHandlers.event_5 = KTUtil.on(element, '[data-ktmenu-submenu-toggle="tab"] > .kt-menu__toggle, [data-ktmenu-submenu-toggle="tab"] > .kt-menu__link .kt-menu__toggle', 'click', Plugin.handleSubmenuDropdownTabClick)
      }

      // handle link click
      the.eventHandlers.event_6 = KTUtil.on(element, '.kt-menu__item > .kt-menu__link:not(.kt-menu__toggle):not(.kt-menu__link--toggle-skip)', 'click', Plugin.handleLinkClick)

      // Init scrollable menu
      if (the.options.scroll && the.options.scroll.height) {
        Plugin.scrollInit()
      }
    },

    /**
         * Reset menu
         * @returns {KTMenu}
         */
    reset() {
      KTUtil.off(element, 'click', the.eventHandlers.event_1)

      // dropdown submenu - hover toggle
      KTUtil.off(element, 'mouseover', the.eventHandlers.event_2)
      KTUtil.off(element, 'mouseout', the.eventHandlers.event_3)

      // dropdown submenu - click toggle
      KTUtil.off(element, 'click', the.eventHandlers.event_4)
      KTUtil.off(element, 'click', the.eventHandlers.event_5)

      // handle link click
      KTUtil.off(element, 'click', the.eventHandlers.event_6)
    },

    /**
         * Init scroll menu
         *
        */
    scrollInit() {
      if (the.options.scroll && the.options.scroll.height) {
        KTUtil.scrollDestroy(element)
        KTUtil.scrollInit(element, {
          mobileNativeScroll: true, windowScroll: false, resetHeightOnDestroy: true, handleWindowResize: true, height: the.options.scroll.height, rememberPosition: the.options.scroll.rememberPosition,
        })
      } else {
        KTUtil.scrollDestroy(element)
      }
    },

    /**
         * Update scroll menu
        */
    scrollUpdate() {
      if (the.options.scroll && the.options.scroll.height) {
        KTUtil.scrollUpdate(element)
      }
    },

    /**
         * Scroll top
        */
    scrollTop() {
      if (the.options.scroll && the.options.scroll.height) {
        KTUtil.scrollTop(element)
      }
    },

    /**
         * Get submenu mode for current breakpoint and menu state
         * @returns {KTMenu}
         */
    getSubmenuMode(el) {
      if (KTUtil.isInResponsiveRange('desktop')) {
        if (el && KTUtil.hasAttr(el, 'data-ktmenu-submenu-toggle') && KTUtil.attr(el, 'data-ktmenu-submenu-toggle') == 'hover') {
          return 'dropdown'
        }

        if (KTUtil.isset(the.options.submenu, 'desktop.state.body')) {
          if (KTUtil.hasClasses(body, the.options.submenu.desktop.state.body)) {
            return the.options.submenu.desktop.state.mode
          }
          return the.options.submenu.desktop.default
        } if (KTUtil.isset(the.options.submenu, 'desktop')) {
          return the.options.submenu.desktop
        }
      } else if (KTUtil.isInResponsiveRange('tablet') && KTUtil.isset(the.options.submenu, 'tablet')) {
        return the.options.submenu.tablet
      } else if (KTUtil.isInResponsiveRange('mobile') && KTUtil.isset(the.options.submenu, 'mobile')) {
        return the.options.submenu.mobile
      } else {
        return false
      }
    },

    /**
         * Get submenu mode for current breakpoint and menu state
         * @returns {KTMenu}
         */
    isConditionalSubmenuDropdown() {
      if (KTUtil.isInResponsiveRange('desktop') && KTUtil.isset(the.options.submenu, 'desktop.state.body')) {
        return true
      }
      return false
    },


    /**
         * Reset submenu attributes
         * @returns {KTMenu}
         */
    resetSubmenuProps(e) {
      const submenus = KTUtil.findAll(element, '.kt-menu__submenu')
      if (submenus) {
        for (let i = 0, len = submenus.length; i < len; i++) {
          KTUtil.css(submenus[0], 'display', '')
          KTUtil.css(submenus[0], 'overflow', '')
        }
      }
    },

    /**
         * Handles submenu hover toggle
         * @returns {KTMenu}
         */
    handleSubmenuDrodownHoverEnter(e) {
      if (Plugin.getSubmenuMode(this) === 'accordion') {
        return
      }

      if (the.resumeDropdownHover() === false) {
        return
      }

      const item = this

      if (item.getAttribute('data-hover') == '1') {
        item.removeAttribute('data-hover')
        clearTimeout(item.getAttribute('data-timeout'))
        item.removeAttribute('data-timeout')
      }

      Plugin.showSubmenuDropdown(item)
    },

    /**
         * Handles submenu hover toggle
         * @returns {KTMenu}
         */
    handleSubmenuDrodownHoverExit(e) {
      if (the.resumeDropdownHover() === false) {
        return
      }

      if (Plugin.getSubmenuMode(this) === 'accordion') {
        return
      }

      const item = this
      const time = the.options.dropdown.timeout

      const timeout = setTimeout(() => {
        if (item.getAttribute('data-hover') == '1') {
          Plugin.hideSubmenuDropdown(item, true)
        }
      }, time)

      item.setAttribute('data-hover', '1')
      item.setAttribute('data-timeout', timeout)
    },

    /**
         * Handles submenu click toggle
         * @returns {KTMenu}
         */
    handleSubmenuDropdownClick(e) {
      if (Plugin.getSubmenuMode(this) === 'accordion') {
        return
      }

      const item = this.closest('.kt-menu__item')

      if (item.getAttribute('data-ktmenu-submenu-mode') == 'accordion') {
        return
      }

      if (KTUtil.hasClass(item, 'kt-menu__item--hover') === false) {
        KTUtil.addClass(item, 'kt-menu__item--open-dropdown')
        Plugin.showSubmenuDropdown(item)
      } else {
        KTUtil.removeClass(item, 'kt-menu__item--open-dropdown')
        Plugin.hideSubmenuDropdown(item, true)
      }

      e.preventDefault()
    },

    /**
         * Handles tab click toggle
         * @returns {KTMenu}
         */
    handleSubmenuDropdownTabClick(e) {
      if (Plugin.getSubmenuMode(this) === 'accordion') {
        return
      }

      const item = this.closest('.kt-menu__item')

      if (item.getAttribute('data-ktmenu-submenu-mode') == 'accordion') {
        return
      }

      if (KTUtil.hasClass(item, 'kt-menu__item--hover') == false) {
        KTUtil.addClass(item, 'kt-menu__item--open-dropdown')
        Plugin.showSubmenuDropdown(item)
      }

      e.preventDefault()
    },

    /**
         * Handles link click
         * @returns {KTMenu}
         */
    handleLinkClick(e) {
      const submenu = this.closest('.kt-menu__item.kt-menu__item--submenu') //

      const result = Plugin.eventTrigger('linkClick', this, e)
      if (result === false) {
        return
      }

      if (submenu && Plugin.getSubmenuMode(submenu) === 'dropdown') {
        Plugin.hideSubmenuDropdowns()
      }
    },

    /**
         * Handles submenu dropdown close on link click
         * @returns {KTMenu}
         */
    handleSubmenuDropdownClose(e, el) {
      // exit if its not submenu dropdown mode
      if (Plugin.getSubmenuMode(el) === 'accordion') {
        return
      }

      const shown = element.querySelectorAll('.kt-menu__item.kt-menu__item--submenu.kt-menu__item--hover:not(.kt-menu__item--tabs)')

      // check if currently clicked link's parent item ha
      if (shown.length > 0 && KTUtil.hasClass(el, 'kt-menu__toggle') === false && el.querySelectorAll('.kt-menu__toggle').length === 0) {
        // close opened dropdown menus
        for (let i = 0, len = shown.length; i < len; i++) {
          Plugin.hideSubmenuDropdown(shown[0], true)
        }
      }
    },

    /**
         * helper functions
         * @returns {KTMenu}
         */
    handleSubmenuAccordion(e, el) {
      let query
      const item = el || this

      if (Plugin.getSubmenuMode(el) === 'dropdown' && (query = item.closest('.kt-menu__item'))) {
        if (query.getAttribute('data-ktmenu-submenu-mode') != 'accordion') {
          e.preventDefault()
          return
        }
      }

      const li = item.closest('.kt-menu__item')
      const submenu = KTUtil.child(li, '.kt-menu__submenu, .kt-menu__inner')

      if (KTUtil.hasClass(item.closest('.kt-menu__item'), 'kt-menu__item--open-always')) {
        return
      }

      if (li && submenu) {
        e.preventDefault()
        const speed = the.options.accordion.slideSpeed
        const hasClosables = false

        if (KTUtil.hasClass(li, 'kt-menu__item--open') === false) {
          // hide other accordions
          if (the.options.accordion.expandAll === false) {
            const subnav = item.closest('.kt-menu__nav, .kt-menu__subnav')
            const closables = KTUtil.children(subnav, '.kt-menu__item.kt-menu__item--open.kt-menu__item--submenu:not(.kt-menu__item--here):not(.kt-menu__item--open-always)')

            if (subnav && closables) {
              for (let i = 0, len = closables.length; i < len; i++) {
                var el_ = closables[0]
                const submenu_ = KTUtil.child(el_, '.kt-menu__submenu')
                if (submenu_) {
                  KTUtil.slideUp(submenu_, speed, () => {
                    Plugin.scrollUpdate()
                    KTUtil.removeClass(el_, 'kt-menu__item--open')
                  })
                }
              }
            }
          }

          KTUtil.slideDown(submenu, speed, () => {
            Plugin.scrollToItem(item)
            Plugin.scrollUpdate()

            Plugin.eventTrigger('submenuToggle', submenu, e)
          })

          KTUtil.addClass(li, 'kt-menu__item--open')
        } else {
          KTUtil.slideUp(submenu, speed, () => {
            Plugin.scrollToItem(item)
            Plugin.eventTrigger('submenuToggle', submenu, e)
          })

          KTUtil.removeClass(li, 'kt-menu__item--open')
        }
      }
    },

    /**
         * scroll to item function
         * @returns {KTMenu}
         */
    scrollToItem(item) {
      // handle auto scroll for accordion submenus
      if (KTUtil.isInResponsiveRange('desktop') && the.options.accordion.autoScroll && element.getAttribute('data-ktmenu-scroll') !== '1') {
        KTUtil.scrollTo(item, the.options.accordion.autoScrollSpeed)
      }
    },

    /**
         * Hide submenu dropdown
         * @returns {KTMenu}
         */
    hideSubmenuDropdown(item, classAlso) {
      // remove submenu activation class
      if (classAlso) {
        KTUtil.removeClass(item, 'kt-menu__item--hover')
        KTUtil.removeClass(item, 'kt-menu__item--active-tab')
      }

      // clear timeout
      item.removeAttribute('data-hover')

      if (item.getAttribute('data-ktmenu-dropdown-toggle-class')) {
        KTUtil.removeClass(body, item.getAttribute('data-ktmenu-dropdown-toggle-class'))
      }

      const timeout = item.getAttribute('data-timeout')
      item.removeAttribute('data-timeout')
      clearTimeout(timeout)
    },

    /**
         * Hide submenu dropdowns
         * @returns {KTMenu}
         */
    hideSubmenuDropdowns() {
      let items
      if (items = element.querySelectorAll('.kt-menu__item--submenu.kt-menu__item--hover:not(.kt-menu__item--tabs):not([data-ktmenu-submenu-toggle="tab"])')) {
        for (let j = 0, cnt = items.length; j < cnt; j++) {
          Plugin.hideSubmenuDropdown(items[j], true)
        }
      }
    },

    /**
         * helper functions
         * @returns {KTMenu}
         */
    showSubmenuDropdown(item) {
      // close active submenus
      const list = element.querySelectorAll('.kt-menu__item--submenu.kt-menu__item--hover, .kt-menu__item--submenu.kt-menu__item--active-tab')

      if (list) {
        for (let i = 0, len = list.length; i < len; i++) {
          const el = list[i]
          if (item !== el && el.contains(item) === false && item.contains(el) === false) {
            Plugin.hideSubmenuDropdown(el, true)
          }
        }
      }

      // add submenu activation class
      KTUtil.addClass(item, 'kt-menu__item--hover')

      if (item.getAttribute('data-ktmenu-dropdown-toggle-class')) {
        KTUtil.addClass(body, item.getAttribute('data-ktmenu-dropdown-toggle-class'))
      }
    },

    /**
         * Handles submenu slide toggle
         * @returns {KTMenu}
         */
    createSubmenuDropdownClickDropoff(el) {
      let query
      const zIndex = (query = KTUtil.child(el, '.kt-menu__submenu') ? KTUtil.css(query, 'z-index') : 0) - 1

      const dropoff = document.createElement(`<div class="kt-menu__dropoff" style="background: transparent; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: ${zIndex}"></div>`)

      body.appendChild(dropoff)

      KTUtil.addEvent(dropoff, 'click', function (e) {
        e.stopPropagation()
        e.preventDefault()
        KTUtil.remove(this)
        Plugin.hideSubmenuDropdown(el, true)
      })
    },

    /**
         * Handles submenu hover toggle
         * @returns {KTMenu}
         */
    pauseDropdownHover(time) {
      const date = new Date()

      the.pauseDropdownHoverTime = date.getTime() + time
    },

    /**
         * Handles submenu hover toggle
         * @returns {KTMenu}
         */
    resumeDropdownHover() {
      const date = new Date()

      return (date.getTime() > the.pauseDropdownHoverTime)
    },

    /**
         * Reset menu's current active item
         * @returns {KTMenu}
         */
    resetActiveItem(item) {
      let list
      let parents

      list = element.querySelectorAll('.kt-menu__item--active')

      for (var i = 0, len = list.length; i < len; i++) {
        const el = list[0]
        KTUtil.removeClass(el, 'kt-menu__item--active')
        KTUtil.hide(KTUtil.child(el, '.kt-menu__submenu'))
        parents = KTUtil.parents(el, '.kt-menu__item--submenu') || []

        for (let i_ = 0, len_ = parents.length; i_ < len_; i_++) {
          const el_ = parents[i]
          KTUtil.removeClass(el_, 'kt-menu__item--open')
          KTUtil.hide(KTUtil.child(el_, '.kt-menu__submenu'))
        }
      }

      // close open submenus
      if (the.options.accordion.expandAll === false) {
        if (list = element.querySelectorAll('.kt-menu__item--open')) {
          for (var i = 0, len = list.length; i < len; i++) {
            KTUtil.removeClass(parents[0], 'kt-menu__item--open')
          }
        }
      }
    },

    /**
         * Sets menu's active item
         * @returns {KTMenu}
         */
    setActiveItem(item) {
      // reset current active item
      Plugin.resetActiveItem()

      const parents = KTUtil.parents(item, '.kt-menu__item--submenu') || []
      for (let i = 0, len = parents.length; i < len; i++) {
        KTUtil.addClass(KTUtil.get(parents[i]), 'kt-menu__item--open')
      }

      KTUtil.addClass(KTUtil.get(item), 'kt-menu__item--active')
    },

    /**
         * Returns page breadcrumbs for the menu's active item
         * @returns {KTMenu}
         */
    getBreadcrumbs(item) {
      let query
      const breadcrumbs = []
      const link = KTUtil.child(item, '.kt-menu__link')

      breadcrumbs.push({
        text: (query = KTUtil.child(link, '.kt-menu__link-text') ? query.innerHTML : ''),
        title: link.getAttribute('title'),
        href: link.getAttribute('href'),
      })

      const parents = KTUtil.parents(item, '.kt-menu__item--submenu')
      for (let i = 0, len = parents.length; i < len; i++) {
        const submenuLink = KTUtil.child(parents[i], '.kt-menu__link')

        breadcrumbs.push({
          text: (query = KTUtil.child(submenuLink, '.kt-menu__link-text') ? query.innerHTML : ''),
          title: submenuLink.getAttribute('title'),
          href: submenuLink.getAttribute('href'),
        })
      }

      return breadcrumbs.reverse()
    },

    /**
         * Returns page title for the menu's active item
         * @returns {KTMenu}
         */
    getPageTitle(item) {
      let query

      return (query = KTUtil.child(item, '.kt-menu__link-text') ? query.innerHTML : '')
    },

    /**
         * Trigger events
         */
    eventTrigger(name, target, e) {
      for (let i = 0; i < the.events.length; i++) {
        const event = the.events[i]
        if (event.name == name) {
          if (event.one == true) {
            if (event.fired == false) {
              the.events[i].fired = true
              return event.handler.call(this, target, e)
            }
          } else {
            return event.handler.call(this, target, e)
          }
        }
      }
    },

    addEvent(name, handler, one) {
      the.events.push({
        name,
        handler,
        one,
        fired: false,
      })
    },

    removeEvent(name) {
      if (the.events[name]) {
        delete the.events[name]
      }
    },
  }

  // ////////////////////////
  // ** Public Methods ** //
  // ////////////////////////

  /**
     * Set default options
     */

  the.setDefaults = function (options) {
    defaultOptions = options
  }

  /**
     * Update scroll
     */
  the.scrollUpdate = function () {
    return Plugin.scrollUpdate()
  }

  /**
     * Re-init scroll
     */
  the.scrollReInit = function () {
    return Plugin.scrollInit()
  }

  /**
     * Scroll top
     */
  the.scrollTop = function () {
    return Plugin.scrollTop()
  }

  /**
     * Set active menu item
     */
  the.setActiveItem = function (item) {
    return Plugin.setActiveItem(item)
  }

  the.reload = function () {
    return Plugin.reload()
  }

  the.update = function (options) {
    return Plugin.update(options)
  }

  /**
     * Set breadcrumb for menu item
     */
  the.getBreadcrumbs = function (item) {
    return Plugin.getBreadcrumbs(item)
  }

  /**
     * Set page title for menu item
     */
  the.getPageTitle = function (item) {
    return Plugin.getPageTitle(item)
  }

  /**
     * Get submenu mode
     */
  the.getSubmenuMode = function (el) {
    return Plugin.getSubmenuMode(el)
  }

  /**
     * Hide dropdown
     * @returns {Object}
     */
  the.hideDropdown = function (item) {
    Plugin.hideSubmenuDropdown(item, true)
  }

  /**
     * Hide dropdowns
     * @returns {Object}
     */
  the.hideDropdowns = function () {
    Plugin.hideSubmenuDropdowns()
  }

  /**
     * Disable menu for given time
     * @returns {Object}
     */
  the.pauseDropdownHover = function (time) {
    Plugin.pauseDropdownHover(time)
  }

  /**
     * Disable menu for given time
     * @returns {Object}
     */
  the.resumeDropdownHover = function () {
    return Plugin.resumeDropdownHover()
  }

  /**
     * Register event
     */
  the.on = function (name, handler) {
    return Plugin.addEvent(name, handler)
  }

  the.off = function (name) {
    return Plugin.removeEvent(name)
  }

  the.one = function (name, handler) {
    return Plugin.addEvent(name, handler, true)
  }

  // /////////////////////////////
  // ** Plugin Construction ** //
  // /////////////////////////////

  // Run plugin
  Plugin.construct.apply(the, [options])

  // Handle plugin on window resize
  KTUtil.addResizeHandler(() => {
    if (init) {
      the.reload()
    }
  })

  // Init done
  init = true

  // Return plugin instance
  return the
}

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = KTMenu
}

// Plugin global lazy initialization
document.addEventListener('click', (e) => {
  const body = KTUtil.get('body')
  let query
  if (query = body.querySelectorAll('.kt-menu__nav .kt-menu__item.kt-menu__item--submenu.kt-menu__item--hover:not(.kt-menu__item--tabs)[data-ktmenu-submenu-toggle="click"]')) {
    for (let i = 0, len = query.length; i < len; i++) {
      const element = query[i].closest('.kt-menu__nav').parentNode

      if (element) {
        const the = KTUtil.data(element).get('menu')

        if (!the) {
          break
        }

        if (!the || the.getSubmenuMode() !== 'dropdown') {
          break
        }

        if (e.target !== element && element.contains(e.target) === false) {
          the.hideDropdowns()
        }
      }
    }
  }
})

export default KTMenu
