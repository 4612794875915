export const isRequired = (name) => `${name} không được rỗng.`
export const isNumber = (name) => `${name} là số.`

export const GATEWAY_STATUS = {
  0: 'Mất điện',
  1: 'Bình thường (tắt)',
  2: 'Bình thường (bật)',
  3: 'Sự cố quá áp pha 1',
  4: 'Sự cố quá áp pha 2',
  5: 'Sự cố quá áp pha 3',
  6: 'Sự cố quá dòng pha 1',
  7: 'Sự cố quá dòng pha 2',
  8: 'Sự cố quá dòng pha 3',
  9: 'Sự cố dòng rò',
  10: 'Mất kết nối',
}

export const ROUTE = {
    home: () => '/gateway/googleMap',
    login: () => '/auth/login'
}

export const SERVER_API = `${process.env.REACT_APP_BACKEND_SERVER}:${process.env.REACT_APP_BACKEND_PORT}`

