/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import { Nav, Tab, Dropdown } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash'
import Badge from '@material-ui/core/Badge'
import { withRouter } from 'react-router-dom'
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle'
import { ReactComponent as CompilingIcon } from '../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg'
import api from '../../api/api'
import { ListenerRealtime } from '../../components/ListenerRealtime'
import { localTime } from '../../common/function'
import Empty from '../../components/Empty/index'

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
}

class UserNotifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newLight: [],
      newGateway: [],
      key: 'gateway',
    }
    this.socketListener = this.socketListener.bind(this)
  }

  getHeaderTopBarCssClassList = () => {
    let result = 'kt-header__topbar-icon '
    if (this.props.pulse) {
      result += 'kt-pulse kt-pulse--brand '
    }

    const { iconType } = this.props
    if (iconType) {
      result += `kt-header__topbar-icon--${iconType}`
    }

    return result
  };

  getSvgCssClassList = () => {
    let result = 'kt-svg-icon '
    const { iconType } = this.props
    if (iconType) {
      result += `kt-svg-icon--${iconType}`
    }

    return result
  };

  getHetBackGroundCssClassList = () => {
    let result = 'kt-head '
    if (this.props.skin) {
      result += `kt-head--skin-${this.props.skin} `
    }

    result += 'kt-head--fit-x kt-head--fit-b'
    return result
  };

  backGroundStyle = () => {
    if (!this.props.bgImage) {
      return 'none'
    }

    return `url(${this.props.bgImage})`
  }

  userNotificationsButtonCssClassList = () => {
    let result = 'btn '
    if (this.props.type) {
      result += `btn-${this.props.type} `
    }

    result += 'btn-sm btn-bold btn-font-md'
    return result
  }

  ulTabsClassList = () => {
    let result = 'nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  '
    if (this.props.type) {
      result += `nav-tabs-line-${this.props.type} `
    }

    result += 'kt-notification-item-padding-x'
    return result
  }

  componentDidMount() {
    // this.newLight()
    this.newGateway()
  }

  socketListener = (newLight) => {
    this.setState((prev) => ({ newLight: [newLight, ...prev.newLight] }))
  }

  // newLight = () => {
  //   api('api/new-light').then((data) => {
  //     this.setState((prev) => ({ newLight: [...data, ...prev.newLight] }))
  //   })
  //
  //   socket.removeListener(
  //     process.env.REACT_APP_NEW_LIGHT_SOCKET,
  //     this.socketListener,
  //   )
  //   socket.on(process.env.REACT_APP_NEW_LIGHT_SOCKET, this.socketListener)
  // }

  newGateway = () => {
    api('api/gateway/new-gateway/list').then((data) => {
      this.setState({ newGateway: data })
    })
  }

  redirectLight = (light) => {
    // const button = document.getElementById('notification-for-new-light')
    // button.click()
    // eslint-disable-next-line no-underscore-dangle
    // useHistory.push(`/new-light?id=${light._id}`)
  }

  redirectGateway = (gateway) => {
    // const button = document.getElementById('notification-for-new-light')
    // button.click()
    // eslint-disable-next-line no-underscore-dangle
    this.props.history.push(`/new-gateway/?GID=${gateway.GID}`)
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { key, newLight, newGateway } = this.state
    const { useSVG, icon, pulse } = this.props
    return (
      <Fragment>
        <ListenerRealtime
          onSocket={process.env.REACT_APP_GATEWAY_NEW_CHANGE_SOCKET}
          onRefresh={this.newGateway}
        />
        <Dropdown
          className="kt-header__topbar-item"
          drop="down"
          alignRight
          id="notification-for-new-light"
        >
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-user-notifications"
          >
          <span className={this.getHeaderTopBarCssClassList()}>
            {!useSVG && <i className={icon} />}

            {useSVG && (
              <Badge
                color="secondary"
                badgeContent={newGateway.length}
                invisible={newGateway.length === 0}
              >
                <span className={this.getSvgCssClassList()}>
                  <CompilingIcon />
                </span>
              </Badge>
            )}
            <span className="kt-pulse__ring" hidden={!pulse} />
          </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className={this.getHetBackGroundCssClassList()}
                style={{ backgroundImage: this.backGroundStyle() }}
              >
                <h3 className="kt-head__title">
                  Thông báo&nbsp;
                  <span className={this.userNotificationsButtonCssClassList()}>
                  <a href="/new-gateway">{newGateway.length}</a>
                </span>
                </h3>

                <Tab.Container
                  defaultActiveKey={key}
                  className={this.ulTabsClassList()}
                >
                  <Nav
                    className={this.ulTabsClassList()}
                    onSelect={(_key) => this.setState({ key: _key })}
                  >
                     {/* <Nav.Item className="nav-item"> */}
                     {/* <Nav.Link eventKey="Alerts" className="nav-link show"> */}
                     {/*   Đèn mới ({newLight.length}) */}
                     {/* </Nav.Link> */}
                     {/* </Nav.Item> */}
                    <Nav.Item className="nav-item">
                      <Nav.Link eventKey="gateway" className="nav-link show">
                        Tủ mới ({newGateway.length})
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="nav-item"> */}
                    {/*  <Nav.Link eventKey="Logs" className="nav-link show"> */}
                    {/*    Khác */}
                    {/*  </Nav.Link> */}
                    {/* </Nav.Item> */}
                  </Nav>

                   {/* <Tab.Content> */}
                   {/* <Tab.Pane eventKey="light"> */}
                   {/*   <PerfectScrollbar */}
                   {/*     options={perfectScrollbarOptions} */}
                   {/*     style={{ */}
                   {/*       maxHeight: '100vh', */}
                   {/*       position: 'relative', */}
                   {/*     }} */}
                   {/*   > */}
                   {/*     <div */}
                   {/*       className="kt-notification kt-margin-t-10 kt-margin-b-10" */}
                   {/*       style={{ */}
                   {/*         maxHeight: '40vh', */}
                   {/*         position: 'relative', */}
                   {/*       }} */}
                   {/*     > */}
                   {/*       <div */}
                   {/*         className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll" */}
                   {/*         data-scroll="true" */}
                   {/*         data-height="300" */}
                   {/*         data-mobile-height="200" */}
                   {/*       > */}
                   {/*         {_.map(newLight, (light) => ( */}
                   {/*           // eslint-disable-next-line no-underscore-dangle */}
                   {/*           <a */}
                   {/*             onClick={() => this.redirectLight(light)} */}
                   {/*             className="kt-notification__item" */}
                   {/*           > */}
                   {/*             <div className="kt-notification__item-icon"> */}
                   {/*               <i className="flaticon2-favourite kt-font-danger" /> */}
                   {/*             </div> */}
                   {/*             <div className="kt-notification__item-details"> */}
                   {/*               <div className="kt-notification__item-title"> */}
                   {/*                 Đèn {light.NID} */}
                   {/*               </div> */}
                   {/*               <div className="kt-notification__item-time"> */}
                   {/*                 {light.updatedAt} */}
                   {/*               </div> */}
                   {/*             </div> */}
                   {/*           </a> */}
                   {/*         ))} */}
                   {/*       </div> */}
                   {/*     </div> */}
                   {/*   </PerfectScrollbar> */}
                   {/* </Tab.Pane> */}
                   {/* </Tab.Content> */}

                  <Tab.Content>
                    <Tab.Pane eventKey="gateway">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        style={{
                          maxHeight: '100vh',
                          position: 'relative',
                        }}
                      >
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10"
                          style={{
                            maxHeight: '40vh',
                            position: 'relative',
                          }}
                        >
                          <div
                            className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                            data-scroll="true"
                            data-height="300"
                            data-mobile-height="200"
                          >
                            {
                              _.size(newGateway) === 0 && <Empty />
                            }
                            {_.map(newGateway, (val) => (
                              // eslint-disable-next-line no-underscore-dangle
                              <a
                                onClick={() => this.redirectGateway(val)}
                                className="kt-notification__item"
                              >
                                <div className="kt-notification__item-icon">
                                  <i className="flaticon2-favourite kt-font-danger" />
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title">
                                    Tủ {val.GID}
                                  </div>
                                  <div className="kt-notification__item-time">
                                    {localTime(val.updatedAt)}
                                  </div>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    )
  }
}

export default withRouter(UserNotifications)
