/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-loop-func,no-undef,no-restricted-globals,strict,no-unused-vars,no-cond-assign,eqeqeq,no-throw-literal,no-unreachable,no-useless-escape,no-redeclare,no-useless-concat,no-unused-expressions,no-sequences */
import KTUtil from './util'

'use strict'
const KTScrolltop = function (elementId, options) {
  // Main object
  let the = this
  let init = false

  // Get element object
  const element = KTUtil.get(elementId)
  const body = KTUtil.get('body')

  if (!element) {
    return
  }

  // Default options
  let defaultOptions = {
    offset: 300,
    speed: 600,
    toggleClass: 'kt-scrolltop--on',
  }

  // //////////////////////////
  // ** Private Methods  ** //
  // //////////////////////////

  var Plugin = {
    /**
         * Run plugin
         * @returns {mscrolltop}
         */
    construct(options) {
      if (KTUtil.data(element).has('scrolltop')) {
        the = KTUtil.data(element).get('scrolltop')
      } else {
        // reset scrolltop
        Plugin.init(options)

        // build scrolltop
        Plugin.build()

        KTUtil.data(element).set('scrolltop', the)
      }

      return the
    },

    /**
         * Handles subscrolltop click toggle
         * @returns {mscrolltop}
         */
    init(options) {
      the.events = []

      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, options)
    },

    build() {
      // handle window scroll
      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        window.addEventListener('touchend', () => {
          Plugin.handle()
        })

        window.addEventListener('touchcancel', () => {
          Plugin.handle()
        })

        window.addEventListener('touchleave', () => {
          Plugin.handle()
        })
      } else {
        window.addEventListener('scroll', () => {
          Plugin.handle()
        })
      }

      // handle button click
      KTUtil.addEvent(element, 'click', Plugin.scroll)
    },

    /**
         * Handles scrolltop click scrollTop
         */
    handle() {
      const pos = window.pageYOffset // current vertical position
      if (pos > the.options.offset) {
        KTUtil.addClass(body, the.options.toggleClass)
      } else {
        KTUtil.removeClass(body, the.options.toggleClass)
      }
    },

    /**
         * Handles scrolltop click scrollTop
         */
    scroll(e) {
      e.preventDefault()

      KTUtil.scrollTop(0, the.options.speed)
    },


    /**
         * Trigger events
         */
    eventTrigger(name, args) {
      for (let i = 0; i < the.events.length; i++) {
        const event = the.events[i]
        if (event.name == name) {
          if (event.one == true) {
            if (event.fired == false) {
              the.events[i].fired = true
              return event.handler.call(this, the, args)
            }
          } else {
            return event.handler.call(this, the, args)
          }
        }
      }
    },

    addEvent(name, handler, one) {
      the.events.push({
        name,
        handler,
        one,
        fired: false,
      })
    },
  }

  // ////////////////////////
  // ** Public Methods ** //
  // ////////////////////////

  /**
     * Set default options
     */

  the.setDefaults = function (options) {
    defaultOptions = options
  }

  /**
     * Get subscrolltop mode
     */
  the.on = function (name, handler) {
    return Plugin.addEvent(name, handler)
  }

  /**
     * Set scrolltop content
     * @returns {mscrolltop}
     */
  the.one = function (name, handler) {
    return Plugin.addEvent(name, handler, true)
  }

  // /////////////////////////////
  // ** Plugin Construction ** //
  // /////////////////////////////

  // Run plugin
  Plugin.construct.apply(the, [options])

  // Init done
  init = true

  // Return plugin instance
  return the
}

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = KTScrolltop
}

export default KTScrolltop
